.site-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.site-content {
  display: grid;
  gap: 10px 20px;
  grid-template-columns: repeat(auto-fit, 375px);
  width: 100%;
  max-width: 1200px;
  justify-content: center;
}

.site-container h4 {
  margin: 40px 10px;
}

@media screen and (max-width: 1366px) {
  .site-content {
    grid-template-columns: repeat(auto-fit, 310px);
  }
}