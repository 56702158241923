.block-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #DDD;
  border-radius: 5px;
  padding: 20px;
  -webkit-box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.15);
}

.block-container span {
  white-space: pre-wrap;
}

.block-container a {
  margin-left: 10px;
}