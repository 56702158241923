.header-container {
  display: flex;
  width: 100%;
  padding: 5px;
  background-color: #C1272D;
  align-items: center;
  justify-content: center;

  -webkit-box-shadow: 10px 10px 29px -24px rgba(100, 100, 100, 0.75);
  -moz-box-shadow: 10px 10px 29px -24px rgba(100, 100, 100, 0.75);
  box-shadow: 10px 10px 29px -24px rgba(100, 100, 100, 0.75);

}

.header-container img {
  max-width: 60px;
}