@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  background: #FFF;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

input,
button {
  font-family: 'Roboto', sans-serif;
}

button {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfdfdf;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(80, 80, 80);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Animations*/
.slide-in-top {
  -webkit-animation: slide-in-top 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: slide-in-top 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-top {
  -webkit-animation: fade-in-top 1s cubic-bezier(.39, .575, .565, 1.000) both;
  animation: fade-in-top 1s cubic-bezier(.39, .575, .565, 1.000) both
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}